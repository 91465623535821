import {
    Box,
    Button,
    Flex,
    Icon,
    Input,
    Table,
    Tbody,
    Td,
    Text,
    Tfoot,
    Th,
    Thead,
    Tr,
    useColorModeValue, useDisclosure
} from "@chakra-ui/react";
import {PiGenderFemaleBold, PiGenderMaleBold} from "react-icons/pi";
import React from "react";
import ConditionalCollapse from "../../../../components/ConditionalCollapse/ConditionalCollapse";
import Card from "../../../../components/card/Card";
import ClassSelector from "./ClassSelector";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import Help from "../../../../components/help/Help";
import useSyncReduxCache from "../../../../hooks/useSyncReduxCache";
import {useDispatch, useSelector} from "react-redux";
import {collapseBlock, expandBlock} from "../../../../slices/collapse";

export function StudentRecordTable({title, id, data, section, subsection, subsection_attribute, helpMessage}: {
    id: string
    title: string
    section: string
    subsection: "student_enrollment_by_age" | "repeaters_by_age" | "disabled_by_age" | "particular_situation_by_age"
    subsection_attribute: "years" | "status" | "kind" | "situation"
    helpMessage: string,
    data: StudentsRecord,
}) {
    const inputWidth = "70px";
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const iconColor = useColorModeValue('brand.500', 'white');
    const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const bgHover = useColorModeValue({bg: 'secondaryGray.400'}, {bg: 'whiteAlpha.50'});
    const bgFocus = useColorModeValue({bg: 'secondaryGray.300'}, {bg: 'whiteAlpha.100'})
    const dispatch = useDispatch();
    const expandedBlocks = useSelector((state: {
        collapse: {
            expandedBlocks: string[];
        };
    }) => state.collapse.expandedBlocks);

    const isExpanded = expandedBlocks.includes(id);
    const [value,
        setValue] = useSyncReduxCache(data[subsection],
        section,
        `detail.data.${subsection}`
    );
    const record = value
    const classes = Object.keys(record)
    const [selectedClass, setSelectedClass] = React.useState(classes[0]);
    const selectedRecord = record[selectedClass]
    // @ts-ignore
    const rows = Object.keys(selectedRecord[subsection_attribute])

    // @ts-ignore
    const sumBoys = Object.entries(selectedRecord[subsection_attribute])
        .reduce((total, [key, value]) => {
            // @ts-ignore

            return total + (value.boys !== null ? value.boys : 0);
        }, 0)
    // @ts-ignore

    const sumGirls = Object.entries(selectedRecord[subsection_attribute])
        .reduce((total, [key, value]) => {
            // @ts-ignore

            return total + (value.girls !== null ? value.girls : 0);
        }, 0)
    const sumTotal = sumBoys + sumGirls


    return (
        <Card flexDirection='column' w='100%' px='0px' overflowX={{sm: 'scroll', lg: 'hidden'}}>
            <Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
                <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
                    {title}
                </Text>
                <Flex>
                    {isExpanded &&
                        <ClassSelector
                            options={classes}
                            value={selectedClass}
                            setValue={setSelectedClass}
                            mr={2}
                        />}
                    <Button
                        alignItems='center'
                        justifyContent='center'
                        bg={bgButton}
                        _hover={bgHover}
                        _focus={bgFocus}
                        _active={bgFocus}
                        w='37px'
                        h='37px'
                        mr={1}
                        lineHeight='100%'
                        onClick={() => {
                            if (isExpanded) {
                                dispatch(collapseBlock(id))
                            } else {
                                dispatch(expandBlock(id))
                            }
                        }}
                        borderRadius='10px'
                    >
                        <Icon
                            as={isExpanded ? IoIosArrowUp : IoIosArrowDown}
                            color={iconColor} w='24px' h='24px'/>
                    </Button>
                    <Help helpMessage={helpMessage}/>
                </Flex>
            </Flex>
            <ConditionalCollapse
                id={id}
                condition={true}
            >
                <Box>

                    <Table size='sm' variant='striped' mb='24px' mt="12px">
                        <Thead>
                            <Tr>
                                <Th>Age</Th>
                                <Th>Garcon <Icon as={PiGenderMaleBold} color={"blue.500"}/></Th>
                                <Th>Fille <Icon as={PiGenderFemaleBold} color={"red.500"}/></Th>
                                <Th>Total</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {rows.map((row) =>
                                <Tr h={"10px"} minH={"1px"}>
                                    <Td>{row}</Td>
                                    <Td><Input w={inputWidth}
                                        // @ts-ignore
                                               value={selectedRecord[subsection_attribute][row].boys === null ? '' :
                                                   // @ts-ignore
                                                   selectedRecord[subsection_attribute][row].boys}
                                               onChange={(e: { target: { value: string; }; }) => {

                                                   const valueEntered = e.target.value === '' ? null : parseInt(e.target.value);
                                                   setValue(// @ts-ignore
                                                       {
                                                           ...record,
                                                           [selectedClass]: {
                                                               [subsection_attribute]: {
                                                                   // @ts-ignore
                                                                   ...record[selectedClass][subsection_attribute],
                                                                   [row]: {
                                                                       // @ts-ignore
                                                                       ...record[selectedClass][subsection_attribute][row],
                                                                       boys: valueEntered
                                                                   }
                                                               }
                                                           }
                                                       })
                                               }}
                                               type={"number"}/></Td>
                                    <Td><Input w={inputWidth}
                                        // @ts-ignore
                                               value={selectedRecord[subsection_attribute][row].girls === null ? '' :
                                                   // @ts-ignore
                                                   selectedRecord[subsection_attribute][row].girls}
                                               onChange={(e: { target: { value: string; }; }) => {

                                                   const valueEntered = e.target.value === '' ? null : parseInt(e.target.value);
                                                   setValue(// @ts-ignore
                                                       {
                                                           ...record,
                                                           [selectedClass]: {
                                                               [subsection_attribute]: {
                                                                   // @ts-ignore
                                                                   ...record[selectedClass][subsection_attribute],
                                                                   [row]: {
                                                                       // @ts-ignore
                                                                       ...record[selectedClass][subsection_attribute][row],
                                                                       girls: valueEntered
                                                                   }
                                                               }
                                                           }
                                                       })
                                               }}/>
                                    </Td>
                                    <Td>{
                                        // @ts-ignore
                                        selectedRecord[subsection_attribute][row].girls + selectedRecord[subsection_attribute][row].boys

                                    }</Td>

                                </Tr>)}


                        </Tbody>
                        <Tfoot>
                            <Tr>
                                <Th>Total</Th>
                                <Td>{
                                    sumBoys
                                }</Td>
                                <Td>{
                                    sumGirls
                                }</Td>
                                <Td>
                                    {
                                        sumTotal
                                    }
                                </Td>

                            </Tr>
                        </Tfoot>

                    </Table>
                </Box>
            </ConditionalCollapse>

        </Card>


    )
}