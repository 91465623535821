import * as React from "react";

import {TableData} from "../../../../components/dataTable/TableData";
import useSyncReduxCache from "../../../../hooks/useSyncReduxCache";

export function OuvragePedagogique({section, data}: {
    section: string,
    data: TeachingAndLearningEquipment
}) {
    const [value,
        setValue] = useSyncReduxCache(
        data.teacher_pedagogical_materials.materials,
        section,
        "detail.data.teacher_pedagogical_materials.materials");
    const classList = ['ci', 'cp', 'ce1', 'ce2', 'cm1', 'cm2'];

    const columns: DataColumn[] = [
        {
            header: "Nom",
            accessor: "name",
            kind: "text",
            isEditable: false,
            width: '40%'
        },
        ...classList.map((classe) => {
            const data = {
                accessor: classe.toUpperCase(),
                header: classe.toUpperCase(),
                isEditable: true,
                kind: 'number',
                width: '10%'
            } as DataColumn
            return data
        })
    ]

    const educationalBooksData: NumberByClass[] = value.map((eqt) => {
        return {
            name: eqt.name,
            CI: eqt.quantities_by_grade.CI,
            CP: eqt.quantities_by_grade.CP,
            CE1: eqt.quantities_by_grade.CE1,
            CE2: eqt.quantities_by_grade.CE2,
            CM1: eqt.quantities_by_grade.CM1,
            CM2: eqt.quantities_by_grade.CM2
        }
    })
    return <TableData<NumberByClass>
        id={'ouvrage_pedagogique'}
        columns={columns}
        setValue={(value: NumberByClass[]) => {
            setValue(value.map((eqt) => {
                return {
                    name: eqt.name,
                    quantities_by_grade: {
                        CI: eqt.CI,
                        CP: eqt.CP,
                        CE1: eqt.CE1,
                        CE2: eqt.CE2,
                        CM1: eqt.CM1,
                        CM2: eqt.CM2
                    }
                }
            }))
        }}


        data={educationalBooksData}
        // helperComponent={<AddButton onClick={onOpenAdd} message={"Ajouter un personnel"}/>}
        title={"Ouvrages pédagogiques à la disposition du maître"}
        helpMessage={"Ouvrages pédagogiques à la disposition du maître"}/>
}