import {Box,  SimpleGrid} from "@chakra-ui/react";

import * as React from "react";
import {EquipementCollectif} from "./components/EquipementCollectif";
import {OuvragePedagogique} from "./components/OuvragePedagogique";
import useGetSectionData from "../../../hooks/useGetSectionData";
import {Loader} from "../../../components/Loader/Loader";


export function TeachingAndLearningEquipment() {
    const section_name = 'teaching_and_learning_equipment'

    const {
        section_data: teaching_and_learning_equipment,
        isLoading
    } = useGetSectionData<TeachingAndLearningEquipment>(section_name);

    return (
      <Box mt={{base: '100px'}} pt={{base: '10px', md: '80px', xl: '80px'}} mx={{base: '30px', lg: '180px'}}>
            {isLoading ? <Loader/> :
                <SimpleGrid columns={{base: 1, md: 1, xl: 1}} gap='20px' mb='20px'>
                    <EquipementCollectif  section={section_name} data={teaching_and_learning_equipment}/>
                    <OuvragePedagogique  section={section_name} data={teaching_and_learning_equipment}/>
                </SimpleGrid>}

        </Box>
    );
}