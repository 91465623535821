import {Flex, Input, Text} from "@chakra-ui/react";
import * as React from "react";
import {createColumnHelper} from "@tanstack/react-table";
import FormComponent from "../../../../components/form/FormComponent";
import useSyncReduxCache from "../../../../hooks/useSyncReduxCache";


export function EquipementCollectif({section, data}: {
    section: string,
    data: TeachingAndLearningEquipment
}) {
    const [value,
        setValue] = useSyncReduxCache(data.collective_equipment,
        section,
        "detail.data.collective_equipment");
    const equipementListe = [
        {
            nom: 'Malettes pédagogiques',
            accesor: "pedagogical_kits"
        },
        {
            nom: 'Cartes murales géographiques',
            accesor: "geographic_wall_maps"
        },
        {
            nom: 'Compendiums métriques',
            accesor: "metric_compendiums"
        },
        {
            nom: 'Compendiums géométriques',
            accesor: "geometric_compendiums"
        },
        {
            nom: 'Compendiums scientifiques',
            accesor: "scientific_compendiums"
        },
        {
            nom: 'Planches scientifiques ',
            accesor: "scientific_boards"
        },
        {
            nom: 'Ordinateurs de bureau',
            accesor: "desktop_computers"
        },
        {
            nom: 'Boîte à images',
            accesor: "image_boxes"
        },
        {
            nom: 'Imprimantes',
            accesor: "printers"
        },
        {
            nom: 'Dictionnaires',
            accesor: "dictionaries"
        },
        {
            nom: 'Caisse à polyèdre',
            accesor: "polyhedron_boxes"
        },
        {
            nom: 'Photocopieurs',
            accesor: "photocopiers"
        },
        {
            nom: "Globes terrestre",
            accesor: "terrestrial_globes"
        }

    ]
    const formElements: FormElement[] = [
        ...equipementListe.map((equipement) => {

            return {
                title: equipement.nom,
                component: <Input
                    // @ts-ignore
                    defaultValue={value[equipement.accesor]}
                    onChange={(e) => {
                        setValue((prev) => {
                            return {...prev,
                                [equipement.accesor]: parseInt(e.target.value)
                            }
                        })
                    }}
                    maxWidth={"400px"}
                    type="number"/>

            }
        })
    ]
    return <>

        <FormComponent isCollapsable={true}
                       id={"collective_equipment"}
                       title={"Equipement collectif"}>
            {formElements.map((item, index) => (
                <Flex w='100%' mb='20px' key={index} alignItems={"center"}
                      justifyContent={"space-between"}>

                    <Text fontSize='md' textAlign='start'>
                        {item.title}
                    </Text>
                    {item.component}
                </Flex>
            ))}</FormComponent>


    </>

}